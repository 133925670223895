define("ember-ref-bucket/modifiers/create-ref", ["exports", "ember-modifier", "@ember/application", "@ember/object", "@ember/debug", "ember-ref-bucket/utils/ref", "ember-ref-bucket/utils/prototype-reference", "@ember/destroyable"], function (_exports, _emberModifier, _application, _object, _debug, _ref, _prototypeReference, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let RefModifier = _exports.default = (_class = class RefModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_key", void 0);
      _defineProperty(this, "_ctx", void 0);
      _defineProperty(this, "_element", void 0);
      // to minimise overhead, user should be specific about
      // what they want to observe
      _defineProperty(this, "defaultMutationObserverOptions", {
        attributes: false,
        characterData: false,
        childList: false,
        subtree: false
      });
      (0, _ref.setGlobalRef)((0, _application.getOwner)(this));
      (0, _destroyable.registerDestructor)(this, () => {
        this.cleanMutationObservers();
        this.cleanResizeObservers();
        (0, _ref.getNodeDestructors)(this._element).forEach(cb => cb());
      });
    }
    markDirty() {
      (0, _ref.bucketFor)(this._ctx).dirtyTrackedCell(this._key);
    }
    cleanMutationObservers() {
      if (this._mutationsObserver) {
        this._mutationsObserver.disconnect();
      }
    }
    cleanResizeObservers() {
      if (this._resizeObserver) {
        this._resizeObserver.unobserve(this.element);
      }
    }
    installMutationObservers(named = {}) {
      this._mutationsObserver = new MutationObserver(this.markDirty);
      const opts = this.getObserverOptions(named);
      delete opts.resize;
      if (opts.attributes || opts.characterdata || opts.childlist) {
        // mutations observer throws if observe is attempted
        // with all these options disabled
        this._mutationsObserver.observe(this.element, opts);
      }
    }
    validateTrackedOptions(named = {}) {
      const args = ['subtree', 'attributes', 'children', 'resize', 'character'];
      if (args.some(name => name in named)) {
        (true && !(this.isTracked(named)) && (0, _debug.assert)(`"ember-ref-modifier", looks like you trying to use {{${named.debugName}}} without tracked flag or alias, but, with properties, related to tracked modifier (${args.join(', ')})`, this.isTracked(named)));
      }
    }
    getObserverOptions(named = {}) {
      // to minimise overhead user
      // should be specific about
      // what they want to observe
      let resize = false;
      let subtree = this.defaultMutationObserverOptions.subtree;
      let attributes = this.defaultMutationObserverOptions.attributes;
      let character = this.defaultMutationObserverOptions.characterData;
      let children = this.defaultMutationObserverOptions.childList;
      if ('subtree' in named) {
        subtree = named.subtree;
      }
      if ('attributes' in named) {
        attributes = named.attributes;
      }
      if ('children' in named) {
        children = named.children;
      }
      if ('resize' in named) {
        resize = named.resize;
      }
      if ('character' in named) {
        character = named.character;
      }
      return {
        subtree,
        attributes,
        childList: children,
        resize,
        characterData: character
      };
    }
    installResizeObservers(element) {
      this._resizeObserver = new ResizeObserver(this.markDirty);
      this._resizeObserver.observe(element);
    }
    modify(element, positional, named) {
      const name = this.name(positional);
      const ctx = this.ctx(named, positional);
      this._key = name;
      this._ctx = ctx;
      this._element = element;
      (true && !(typeof name === 'string' && name.length > 0) && (0, _debug.assert)(`You must provide string as first positional argument for {{${named.debugName}}}`, typeof name === 'string' && name.length > 0));
      this.validateTrackedOptions(named);
      this.cleanMutationObservers();
      this.cleanResizeObservers();
      if (name !== this._key || this._ctx !== ctx) {
        (0, _ref.bucketFor)(this._ctx).add(this._key, null);
      }
      (0, _ref.watchFor)(name, ctx, () => {
        const keys = (0, _prototypeReference.getReferencedKeys)(ctx, name);
        keys.forEach(keyName => {
          // consume keys with callbacks
          ctx[keyName];
        });
      });
      (0, _ref.bucketFor)(ctx).add(name, element);
      if (this.isTracked(named)) {
        this.installMutationObservers(named);
        if (this.getObserverOptions(named).resize) {
          this.installResizeObservers(element);
        }
      }
    }
    ctx(named = {}, positional = [undefined]) {
      (true && !(named.bucket !== null) && (0, _debug.assert)(`ember-ref-bucket: You trying to use {{${named.debugName}}} as local reference for template-only component. Replace it to {{global-ref "${positional[0]}"}}`, named.bucket !== null));
      return named.bucket || (0, _application.getOwner)(this);
    }
    isTracked(named = {}) {
      return named.tracked || false;
    }
    name(positional) {
      return positional[0];
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "markDirty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "markDirty"), _class.prototype)), _class);
});